<template>
  <div>
    <!-- Content -->
    <div class="draw-template-card-wrapper">
      <!-- Email Template -->
      <template v-if="templateType === 'email'">
        <label class="vs-input--label">
          {{ readonly ? 'E-mail:' : 'Selecione um template de e-mail:' }}
        </label>

        <div class="draw-template-card-wrapper__content">
          <span v-if="readonly && !value">
            Nenhum template selecionado.
          </span>
          <div
            v-else
            class="draw-template-card"
          >
            <div class="draw-template-card--preview">
              <div
                v-show="value"
                class="prvw--image"
              >
                <img
                  :src="getTemplatePreview(value)"
                  alt="Nenhum template selecionado"
                >
              </div>
            </div>
          </div>

          <vs-button
            v-if="!readonly"
            class="c-vs-icon-small"
            :color="value !== 0 ? 'success' : 'primary'"
            :icon="value !== 0 ? 'done' : 'add'"
            size="small"
            radius
            @click="openSelectPopup"
          />
        </div>
      </template>

      <!-- Message Template -->
      <vs-select
        v-else-if="templateType === 'sms'"
        v-model="valueModel"
        :disabled="readonly"
        :label="readonly ? 'SMS:' : 'Selecione um template de SMS (Opcional):'"
        :readonly="readonly"
        width="200px"
      >
        <vs-select-item
          v-for="(item, index) in templates"
          :key="index"
          :text="item.messageDescription"
          :value="item.templateCode"
        />
      </vs-select>

      <!-- Message Template -->
      <vs-select
        v-else-if="templateType === 'whatsapp'"
        v-model="valueModel"
        :disabled="readonly"
        :label="readonly ? 'WhatsApp:' : 'Selecione um template de WhatsApp (Opcional):'"
        :readonly="readonly"
        width="200px"
      >
        <vs-select-item
          v-for="(item, index) in templates"
          :key="index"
          :text="item.messageDescription"
          :value="item.templateCode"
        />
      </vs-select>
    </div>

    <!-- Select Email Template Popup -->
    <vs-popup
      title="Selecionar Template"
      :active.sync="selectTemplatePopup"
    >
      <div class="draw-template-popup">
        <div class="template-list">
          <div
            v-for="template in templates"
            :key="template.id"
            class="draw-template-card"
          >
            <div class="draw-template-card--preview">
              <div class="prvw--title-wrapper">
                <div class="prvw--title">
                  <h3>{{ template.value }}</h3>
                </div>
              </div>

              <div class="prvw--image">
                <img
                  :src="template.urlPreview"
                  alt="Preview indisponível"
                >
              </div>
            </div>

            <div class="draw-template-card--controls">
              <vs-button
                text-color="rgb(31, 41, 55)"
                type="flat"
                icon="check"
                size="small"
                @click="selectTemplate(template)"
              />
            </div>
          </div>
        </div>
      </div>
    </vs-popup>
  </div>
</template>

<script>
export default {
  name: 'SelectTemplate',
  props: {
    templates: {
      type: Array,
      required: true,
    },
    templateType: {
      type: String,
      required: true,
    },
    value: {
      type: Number,
      default: 0,
    },
    readonly: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    selectTemplatePopup: false,
  }),
  computed: {
    /* Models */
    valueModel: {
      get() { return this.value; },
      set(value) { this.$emit('set-value', value); },
    },
  },
  methods: {
    /* Helpers */
    getTemplatePreview(templateId) {
      const found = this.templates.find((t) => t.id === templateId);
      if (found) return found.urlPreview;
    },
    /* Emits */
    selectTemplate(value) {
      this.$emit('set-value', value.id);
      this.closeSelectPopup();
    },
    /* Popups */
    closeSelectPopup() {
      this.selectTemplatePopup = false;
    },
    openSelectPopup() {
      this.selectTemplatePopup = true;
    },
  },
};
</script>

<style lang="scss">
.c-vs-icon-small {
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  padding: 16px !important;
  margin-left: -12px;

  .includeIconOnly.small .vs-icon {
    font-size: 0.95 !important;
  }
}

  /* Template Popup */
.draw-template-popup {
  width: 100%;
  display: flex;
  justify-content: center;

  .template-list {
    display: flex;
    flex-flow: row wrap;
    gap: 1rem;
    margin-left: 0.5rem;
  }
}

.draw-template-card-wrapper {
  display: flex;
  flex-flow: column nowrap;
  margin: 0.25rem 0;

  .draw-template-card-wrapper__content {
    align-items: center;
    display: flex;
    flex-flow: row nowrap;
    justify-content: flex-start;
    padding-left: 5px;
  }
}
</style>
